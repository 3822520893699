import React from 'react';
import { Form } from 'informed';
import { useHistory } from 'react-router-dom';
import { Button, Box, Container, CardContent, Grid, Typography, Link, Card } from '@mui/material';

import useToken from "@lottoasean/token-hook";
import useMutation from "@lottoasean/mutation-hook";

import TextInput from 'components/TextInput';
import PublicLayout from "layouts/material-public";
import bgImage from "images/bg.png";
import userRegisterValidation from "formValidations/userRegister";

import { createUser } from "apiRequest/user";
import { login } from "apiRequest/auth";

export default function Register() {
  const history = useHistory();
  const { setToken } = useToken();
  const { loading: createUserLoading, mutate: createUserMutate } = useMutation(createUser);
  const { loading: loginLoading, mutate: loginMutate } = useMutation(login);

  const handleSubmit = async ({ name, phone_number, username, password }) => {
    const data = {
      name,
      phone_number,
      username,
      password
    };

    const { error: createUserError } = await createUserMutate({ data });

    if (createUserError && createUserError.status !== 400) {
      alert("Something was wrong!", "Create user");
      return;
    }

    if (createUserError && createUserError.status === 400) {
      alert("Wrong Credential!", "Create user");
      return;
    }

    const { payload, error: loginError } = await loginMutate({ data: { username, password } });

    if (loginError && loginError.status !== 400) {
      alert("Something was wrong!", "login");
      return;
    }

    if (loginError && loginError.status === 400) {
      alert("Wrong Credential!", "login");
      return;
    }

    setToken(payload.access_token, payload.expires_in);
    history.push('/');;
  };

  return (
    <PublicLayout loading={createUserLoading || loginLoading}>
      <Container
        sx={{
          display: "flex",
          marginBottom: "64px",
          '&::before': {
            content: '""',
            background: `url(${bgImage}) center center no-repeat`,
            backgroundSize: 'cover',
            height: '65vh',
            width: '100%',
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 70%)',
            position: 'absolute',
            left: '0',
            right: '0',
            zIndex: '-1',
          }
        }}
      >
        <Box
          sx={{
            maxWidth: '500px',
            margin: '0 auto',
            position: 'absolute',
            left: '0',
            right: '0',
            top: '30%',
            '@media screen and (max-width: 768px)': {
              maxWidth: '100%',
              margin: '0 16px',
              top: '18%',
            }
          }}
        >
          <Card
            sx={{
              padding: '30px 20px',
            }}
            elevation={4}>
            <CardContent>
              <Form onSubmit={handleSubmit} validationSchema={userRegisterValidation}>
                {() => (
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12} >
                      <Typography variant="h4"
                        sx={{
                          fontSize: '30px',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          marginBottom: '30px',
                          color: '#333',
                        }}
                      >Register</Typography>
                    </Grid>
                    <Grid item xs={12} >
                      <TextInput
                        field="name"
                        label="Name"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextInput
                        field="phone_number"
                        label="Phone Number"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextInput
                        field="username"
                        label="Username"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} >
                      <TextInput
                        field="password"
                        label="Password"
                        type="password"
                        fullWidth
                      />
                    </Grid>
                    <Grid item xs={12} marginBottom="15px">
                      <Button
                        type="submit"
                        disabled={createUserLoading || loginLoading}
                        variant="inherint"
                        size="large"
                        fullWidth
                        sx={{
                          color: '#333',
                          backgroundColor: '#FED249',
                          padding: '8px 10px',
                          fontWeight: '500',
                          ":hover": {
                            backgroundColor: '#f9ca39'
                          }
                        }}
                      >Register</Button>
                    </Grid>
                  </Grid>
                )}
              </Form>
            </CardContent>
          </Card>
          <Typography variant="body2" textAlign="center" mt={2} mb={6}>
            Contact Admin: &nbsp;
            <Link href={`tel:${process.env.REACT_APP_VIBER_NUMBER}`} color="inherit" underline="hover">
              {process.env.REACT_APP_VIBER_NUMBER}
            </Link>
          </Typography>
        </Box>
      </Container>
    </PublicLayout>
  );
}
