import fetch from "@lottoasean/fetch-utils";

export const fetchVerificationUser = ({ token, params }) => {
  return fetch({
    url: 'verifications/user',
    method: "GET",
    params,
    token
  });
};


export const fetchUser = ({ token, params }) => {
  return fetch({
    url: 'user',
    method: "GET",
    params,
    token
  });
};


export const requestOtp = ({ token, data }) => {
  return fetch({
    token,
    url: `verifications/send/otp`,
    method: "POST",
    data
  });
};

export const verifyOtp = ({ token, data }) => {
  return fetch({
    token,
    url: `verifications/verify/otp`,
    method: "POST",
    data
  });
};
