import fetch from "@lottoasean/fetch-utils";

export const fetchTransactions = ({ token, params }) => {
  return fetch({
    url: 'transactions',
    method: "GET",
    params,
    token
  });
};
