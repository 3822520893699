import React, { useEffect } from 'react';
import { Form, useFormApi, } from 'informed';
import { Button, Grid, Typography } from '@mui/material';

import forEach from 'lodash.foreach';
import * as Yup from "yup";
import YupPassword from 'yup-password';

import TextInput from 'components/TextInput';
import CheckboxInput from 'components/CheckboxInput';

YupPassword(Yup);

const validationSchema = Yup.object().shape({
  current_password: Yup.string().required(),
  new_password: Yup.string().min(8).password().required(),
  revoke: Yup.boolean()
});

export default function UpdatePasswordForm({ onSubmit, serverError, loading }) {
  return (
    <Form onSubmit={onSubmit} validationSchema={validationSchema}>
      {() => (
        <Fields serverError={serverError} loading={loading} />
      )}
    </Form>
  );
}

const Fields = ({ serverError, loading }) => {
  const formApi = useFormApi();

  useEffect(() => {
    if (serverError?.status === 422 && serverError.data.errors) {
      forEach(serverError.data.errors, (value, key) => {
        formApi.setError(key, value);
      });
    }
  }, [formApi, serverError]);

  return (
    <>
      <Grid container item spacing={2} md={5} mx={{ md: 'auto' }}>
        <Grid item>
          <Typography variant="button" my={3} component="div"
            sx={{
              fontSize: '20px',
              fontWeight: '600',
              textAlign: 'center',
            }}
          >
            Update Password
          </Typography>
        </Grid>
        <Grid item xs={12} >
          <TextInput
            field="current_password"
            label="Current Password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} >
          <TextInput
            field="new_password"
            label="New Password"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} >
          <CheckboxInput field="revoke" label="Logout from all device" />
        </Grid>
        <Grid item marginBottom="15px">
          <Button
            disabled={loading}
            type="submit"
            variant="inherint"
            size="large"
            sx={{
              width: '120px',
              color: '#333',
              backgroundColor: '#FED249',
              padding: '8px 10px',
              fontWeight: '500',
              ":hover": {
                backgroundColor: '#f9ca39'
              }
            }}
          >Submit</Button>
        </Grid>
      </Grid>
    </>
  );
};
