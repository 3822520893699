import React, { useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Card, CardContent, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, Divider } from '@mui/material';

import { toBlob } from 'html-to-image';
import { sumBy } from 'lodash';
import download from 'downloadjs';
import currency from '@lottoasean/currency-utils';
import useQuery from '@lottoasean/query-hook';
import dateTime from '@lottoasean/datetime-utils';

import PrivateLayout from "layouts/material-private";
import BetReceipt from 'components/BetReceipt';

import { fetchBet } from "apiRequest/bet";

export default function BetShow() {
  const { id } = useParams();

  const { loading, error, payload: bet } = useQuery(fetchBet, {
    id,
    params: { partials: ['round', 'variety', 'round.lotto', 'bet_numbers', 'bet_numbers.number'] }
  });

  const totalBetUnit = sumBy(bet?.data.bet_numbers, (b) => b.unit);
  const receiptComponentRef = useRef();

  const handleClickSave = () => {
    toBlob(receiptComponentRef.current).then((dataUrl) => {
      download(dataUrl, "receipt.png");
    });
  };

  return (
    <PrivateLayout loading={loading} error={error}>
      <BetReceipt ref={receiptComponentRef} payload={bet?.data} />
      <Box
        sx={{
          maxHeight: '200px',
          backgroundColor: 'rgb(254,210,74)',
          backgroundImage: 'linear-gradient(180deg, rgba(254,210,74,1) 0%, rgba(229,94,22,1) 100%)',
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom component="div" sx={{
                mt: { md: 5, xs: 3.5 },
                fontSize: '30px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                marginBottom: '30px',
                color: '#fff',
              }} >
                {bet?.data.round?.lotto.title} - {bet?.data.round?.title} ({bet?.data.variety?.title})
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={4}>
                <CardContent>
                  <Grid container mt={2}>
                    <Grid item>
                      <Typography variant="span" fontSize="14px">Bet ID: </Typography>
                      <Typography variant="span" fontWeight="bold" fontSize="14px">{bet?.data.id}</Typography>
                    </Grid>
                    <Divider orientation="vertical" sx={{ margin: '0px 16px', borderStyle: 'dashed', borderColor: '#B4B4B4' }} flexItem />
                    <Grid item >
                      <Typography variant="span" fontSize="14px">Bet Date:</Typography>
                      <Typography variant="span" fontWeight="bold" fontSize="14px">{dateTime.format(bet?.data.created_at)}</Typography>
                    </Grid>
                  </Grid>
                  <TableContainer sx={{
                    paddingTop: "16px"
                  }}>
                    <Table
                      sx={{
                        "& thead tr": {
                          textTransform: 'uppercase',
                          fontWeight: '600',
                          backgroundColor: '#E4E0DD',
                        },
                        "& tbody tr:nth-of-type(odd)": {
                          backgroundColor: '#F4F3F1'
                        },
                        "@media screen and (max-width: 480px)": {
                          "thead tr th, tr td": {
                            padding: '10px'
                          }
                        }
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Number</TableCell>
                          <TableCell>Unit</TableCell>
                          <TableCell>Multiply</TableCell>
                          <TableCell>Win rate</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bet?.data.bet_numbers?.map((bet_number, index) => (
                          <TableRow key={bet_number.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{bet_number.number.number}</TableCell>
                            <TableCell>{currency.format(bet_number.unit)}</TableCell>
                            <TableCell>{bet_number.multiply}</TableCell>
                            <TableCell>{currency.format(bet_number.unit * bet_number.multiply)}</TableCell>
                          </TableRow>
                        ))}
                        <TableRow>
                          <TableCell />
                          <TableCell colSpan={1} sx={{ fontWeight: 'bold' }}>Total</TableCell>
                          <TableCell sx={{ fontWeight: 'bold' }}>{currency.format(totalBetUnit)}</TableCell>
                          <TableCell colSpan={2} />
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={2}>
                    <Button
                      onClick={handleClickSave}
                      variant="contained"
                      disableElevation
                      sx={{
                        width: '120px',
                        color: '#FFF',
                        bgcolor: '#333',
                        ":hover": {
                          bgcolor: '#333',
                        }
                      }}
                    >Save</Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </PrivateLayout>
  );
}
