import fetch from "@lottoasean/fetch-utils";

export const createBet = ({ token, data }) => {
  return fetch({
    token,
    url: `bets`,
    method: "POST",
    data
  });
};

export const fetchBets = ({ token, params }) => {
  return fetch({
    url: 'bets',
    method: "GET",
    params,
    token
  });
};

export const fetchBet = ({ token, id, params }) => {
  return fetch({
    url: `bets/${id}`,
    method: "GET",
    params,
    token
  });
};
