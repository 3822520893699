import React from 'react';
import { Form, Multistep } from 'informed';

import * as Yup from "yup";

import EnterBetNumbersStep from 'components/forms/steps/EnterBetNumbersStep';
import ConfirmCreateBetStep from 'components/forms/steps/ConfirmCreateBetStep';

Yup.addMethod(Yup.array, 'unique', function (
  mapper = (a) => a,
  message = '${path} may not have duplicates' // eslint-disable-line no-template-curly-in-string
) {
  return this.test('unique', message, list => {
    if (list) {
      const filterdList = list.filter((l) => l !== null);
      return filterdList.length === new Set(filterdList.map(mapper)).size;
    }
  });
});

export default function CreateBetForm({ onSubmit, initialValues, serverError, loading, variety, betsLimit = 10 }) {
  const validationSchema = Yup.object().shape({
    bets: Yup.array().required().min(1).max(betsLimit).of(
      Yup.object().shape({
        number: Yup.string()
          .required("number is a required field")
          .length(variety.digit, `must be exactly ${variety.digit} character(s)`)
          .when({
            is: () => variety.largest_number,
            then: Yup.string().test({
              message: `must be less than or equal to ${variety.largest_number}`,
              test: value => value <= variety.largest_number,
            }),
          }),
        unit: Yup.number().positive().typeError("must be positive number.").min(100).max(100000).required("unit is a required field")
      })
    )
      .unique(a => a.number)
  });

  return (
    <Form onSubmit={onSubmit} initialValues={initialValues || { bets: [{}, {}, {}, {}, {}, {}, {}] }} validationSchema={validationSchema}>
      {() => (
        <Multistep initialStep="inputs">
          <EnterBetNumbersStep variety={variety} betsLimit={betsLimit} />
          <ConfirmCreateBetStep variety={variety} serverError={serverError} loading={loading} />
        </Multistep>
      )}
    </Form >
  );
}
