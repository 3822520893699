import { LinearProgress } from '@mui/material';
import React from 'react';

export default function LoadingProgressBar({ show, ...props }) {
  return show ? (
    <LinearProgress
      sx={{
        '&.MuiLinearProgress-root': {
          bgcolor: 'rgb(249, 185, 185)'
        },
        '& .MuiLinearProgress-bar': {
          bgcolor: '#F14848'
        }
      }}
      {...props}
    />
  ) : "";
}
