import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import useToken from '@lottoasean/token-hook';

export default function PrivateRoute({ children, ...rest }) {
  let { token } = useToken();
  const loggedIn = token != null;

  return (
    <Route
      {...rest}
      render={() => {
        return loggedIn ? children : <Redirect to="/login" />;
      }}
    />
  );
}
