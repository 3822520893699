import { useCallback, useEffect, useState } from "react";
import merge from "lodash.merge";
import useToken from "@lottoasean/token-hook";
import omitBy from "lodash.omitby";

export default function useQuery(action, specification) {
    const { token } = useToken();

    const [payload, setPayload] = useState();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState();

    const handleQuery = useCallback(
        (newSpecification) => {
            setLoading(true);

            let parameter = { token, ...merge(specification, newSpecification) };
            if (parameter.params?.filters) {
                const filters = omitBy(parameter.params.filters, (v) => v === "none");
                parameter = { ...parameter, params: { ...parameter.params, filters } };
            }

            action(parameter).then(({ data }) => {
                setPayload(data);
                setLoading(false);
            }).catch(({ response }) => {
                setError(response);
                setLoading(false);
            });
        },
        [token, action] // eslint-disable-line react-hooks/exhaustive-deps
    );

    useEffect(() => {
        handleQuery({});
    }, [handleQuery]);

    return {
        loading, error, payload, query: handleQuery
    };
}
