import React from 'react';
import { Form } from 'informed';
import { useHistory } from 'react-router-dom';
import { Button, Box, Container, CardContent, Grid, Typography, Link, Card } from '@mui/material';

import useToken from "@lottoasean/token-hook";
import useMutation from "@lottoasean/mutation-hook";

import TextInput from 'components/TextInput';
import PublicLayout from "layouts/material-public";
import bgImage from "images/bg.png";

import { login } from "apiRequest/auth";

export default function Login() {
  const history = useHistory();
  const { setToken } = useToken();
  const { loading, mutate } = useMutation(login);

  const handleSubmit = async ({ username, password }) => {
    const data = {
      username,
      password
    };

    const { payload, error } = await mutate({ data });

    if (error && error.status !== 400) {
      alert("Something was wrong!");
      return;
    }

    if (error && error.status === 400) {
      alert("Wrong Credential!");
      return;
    }

    setToken(payload.access_token, payload.expires_in);
    history.push('/');;
  };

  return (
    <PublicLayout loading={loading}>
      <Container
        sx={{
          display: "flex",
          marginBottom: "64px",
          '&::before': {
            content: '""',
            background: `url(${bgImage}) center center no-repeat`,
            backgroundSize: 'cover',
            height: '65vh',
            width: '100%',
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 70%)',
            position: 'absolute',
            left: '0',
            right: '0',
            zIndex: '-1',
          }
        }}
      >
        <Box
          sx={{
            maxWidth: '500px',
            margin: '0 auto',
            position: 'absolute',
            left: '0',
            right: '0',
            top: '30%',
            '@media screen and (max-width: 768px)': {
              maxWidth: '100%',
              margin: '0 16px',
              top: '18%',
            }
          }}
        >
          <Card
            sx={{
              padding: '30px 20px',
            }}
            elevation={4}>
            <CardContent>
              <Form onSubmit={handleSubmit}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12} >
                    <Typography variant="h4"
                      sx={{
                        fontSize: '30px',
                        fontWeight: 'bold',
                        textTransform: 'uppercase',
                        marginBottom: '30px',
                        color: '#333',
                      }}
                    >Login</Typography>
                  </Grid>
                  <Grid item xs={12} >
                    <TextInput
                      required
                      field="username"
                      label="Username"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} >
                    <TextInput
                      required
                      field="password"
                      label="Password"
                      type="password"
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} marginBottom="15px">
                    <Button
                      type="submit"
                      disabled={loading}
                      variant="inherint"
                      size="large"
                      fullWidth
                      sx={{
                        color: '#fff',
                        backgroundColor: '#22BB00',
                        padding: '8px 10px',
                        fontWeight: '500',
                        ":hover": {
                          backgroundColor: '#1d9e00'
                        }
                      }}
                    >Login</Button>
                  </Grid>
                </Grid>
              </Form>
            </CardContent>
          </Card>
          <Typography variant="body2" textAlign="center" mt={2} mb={6}>
            Contact Admin: &nbsp;
            <Link href={`tel:${process.env.REACT_APP_VIBER_NUMBER}`} color="inherit" underline="hover">
              {process.env.REACT_APP_VIBER_NUMBER}
            </Link>
          </Typography>
        </Box>
      </Container>
    </PublicLayout>
  );
}
