import React from 'react';
import { Box, Card, CardContent, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';

import { formatRelative, parseISO } from 'date-fns';
import currency from "@lottoasean/currency-utils";
import useQuery from '@lottoasean/query-hook';

import PrivateLayout from "layouts/material-private";

import { fetchTransactions } from "apiRequest/transactions";

export default function TransactionList() {

  const { loading, error, payload: transactions } = useQuery(fetchTransactions);

  return (
    <PrivateLayout loading={loading} error={error}>
      <Box
        sx={{
          maxHeight: '200px',
          backgroundColor: 'rgb(254,210,74)',
          backgroundImage: 'linear-gradient(180deg, rgba(254,210,74,1) 0%, rgba(229,94,22,1) 100%)',
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom component="div" sx={{
                mt: { md: 5, xs: 3.5 },
                fontSize: '30px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                marginBottom: '30px',
                color: '#fff',
              }} >
                Tranctions
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={4}>
                <CardContent>
                  <TableContainer sx={{ paddingTop: "16px" }}>
                    <Table
                      sx={{
                        "& thead tr": {
                          textTransform: 'uppercase',
                          fontWeight: '600',
                          backgroundColor: '#E4E0DD',
                        },
                        "& tbody tr:nth-of-type(odd)": {
                          backgroundColor: '#F4F3F1'
                        },
                        "@media screen and (max-width: 480px)": {
                          "thead tr th, tr td": {
                            padding: '10px'
                          }
                        }
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>Type</TableCell>
                          <TableCell>Unit</TableCell>
                          <TableCell>Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {transactions?.data.map((transaction) => (
                          <TableRow key={transaction.id}>
                            <TableCell>{transaction.type}</TableCell>
                            <TableCell>{currency.format(transaction.unit)}</TableCell>
                            <TableCell>{formatRelative(parseISO(transaction.created_at), new Date())}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </PrivateLayout>
  );
}
