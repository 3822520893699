import React, { useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Form } from 'informed';
import { Button, Box, Container, CardContent, Grid, Typography, Link, Card, Skeleton } from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';

import useMutation from "@lottoasean/mutation-hook";
import useQuery from '@lottoasean/query-hook';

import TextInput from 'components/TextInput';
import bgImage from "images/bg.png";
import UnverifiedLayout from 'layouts/material-unverified';

import { fetchVerificationUser, requestOtp, verifyOtp } from 'apiRequest/verification';
import Countdown from 'react-countdown';

export default function Verification() {
  const [requestId, setRequestId] = useState();
  const [lockRequestCode, setLockRequestCode] = useState(false);
  const [lockCountDown, setLockCountDown] = useState(null);
  const [success, setSuccess] = useState(false);

  const { loading: verificationUserLoading, payload: verificationUser } = useQuery(fetchVerificationUser);
  const { loading: requestOtpLoading, mutate: requestOtpMutate } = useMutation(requestOtp);
  const { loading: verifyOtpLoading, mutate: verifyOtpMutate } = useMutation(verifyOtp);

  const handleCountdownComplete = () => {
    setLockRequestCode(false);
    setLockCountDown(null);
  };

  const handleRequestCode = async () => {
    setLockCountDown(45);
    setLockRequestCode(true);

    const { payload, error } = await requestOtpMutate();
    if (error) {
      alert("Something was wrong!");
      return;
    }
    setRequestId(payload.data.request_id);
  };

  const handleSubmit = async ({ code }) => {
    const data = {
      code,
      request_id: requestId,
    };

    const { error } = await verifyOtpMutate({ data });

    if (error && error.status !== 401) {
      alert("Invalid OTP Token");
      return;
    }

    if (error && error.status !== 400) {
      alert("Invalid Request Id!");
      return;
    }

    setSuccess(true);
  };

  return (
    <UnverifiedLayout>
      <Container
        sx={{
          display: "flex",
          marginBottom: "64px",
          '&::before': {
            content: '""',
            background: `url(${bgImage}) center center no-repeat`,
            backgroundSize: 'cover',
            height: '65vh',
            width: '100%',
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 70%)',
            position: 'absolute',
            left: '0',
            right: '0',
            zIndex: '-1',
          }
        }}
      >
        <Box
          sx={{
            maxWidth: '500px',
            margin: '0 auto',
            position: 'absolute',
            left: '0',
            right: '0',
            top: '30%',
            '@media screen and (max-width: 768px)': {
              maxWidth: '100%',
              margin: '0 16px',
              top: '18%',
            }
          }}
        >
          {verificationUserLoading ? (
            <Box sx={{ width: '100%' }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton />
              <Skeleton animation="wave" variant="rectangular" height={150} />
              <Skeleton animation="wave" />
            </Box>
          ) : (
            <Card
              sx={{
                padding: '30px 20px',
              }}
              elevation={4}>
              <CardContent>
                {success ? (
                  <Grid container spacing={2} direction="column">
                    <Grid item xs={12} textAlign="center">
                      <CheckCircleOutlineIcon sx={{ color: '#22BB00', fontSize: '65px', marginBottom: '10px' }} />
                      <Typography variant="h4"
                        sx={{
                          fontSize: '30px',
                          fontWeight: 'bold',
                          textTransform: 'uppercase',
                          marginBottom: '15px',
                          color: '#22BB00',
                        }}
                      >Verified</Typography>
                      <Typography variant="body2" mb={2} sx={{ color: '#333' }}>You have successfully verified. </Typography>
                    </Grid>
                    <Grid item xs={12} marginBottom="15px">
                      <Button
                        component={RouteLink}
                        to="/"
                        variant="inherint"
                        size="large"
                        fullWidth
                        sx={{
                          color: '#fff',
                          backgroundColor: '#22BB00',
                          padding: '8px 10px',
                          fontWeight: '500',
                          ":hover": {
                            backgroundColor: '#1d9e00'
                          }
                        }}
                      >Go to home</Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Form onSubmit={handleSubmit}>
                    <Grid container spacing={2} direction="column">
                      <Grid item xs={12} >
                        <Typography variant="h4"
                          sx={{
                            fontSize: '30px',
                            fontWeight: 'bold',
                            textTransform: 'uppercase',
                            marginBottom: '15px',
                            color: '#333',
                          }}
                        >Verify Phone Number</Typography>
                        <Typography variant="body2" mb={2} sx={{ color: '#333' }}>
                          Enter the code we sent to the phone number <span style={{ fontWeight: "bold" }}>{verificationUser?.data.phone_number}</span>.
                        </Typography>
                        {lockCountDown && <Countdown
                          date={Date.now() + (lockCountDown * 1000)}
                          onComplete={handleCountdownComplete}
                          renderer={({ formatted, api }) => (
                            <Typography variant="body2" sx={{ color: '#ef5350' }}>
                              Get another code after <span sx={{ fontWeight: "bold" }}>{formatted.minutes}:{formatted.seconds}</span>
                            </Typography>
                          )}
                        />}
                      </Grid>
                      <Grid item xs={12}>
                        <Grid container spacing={1.5} >
                          <Grid item md={9} xs={8}>
                            <TextInput label="Enter the code" fullWidth required field="code" />
                          </Grid>
                          <Grid item md={3} xs={4}>
                            <Button
                              className="btn-verify"
                              variant="outlined"
                              onClick={handleRequestCode}
                              disabled={requestOtpLoading || lockRequestCode}
                              sx={{
                                width: '100%',
                                fontSize: '14px',
                                color: '#b68e16',
                                border: '2px solid #FED249',
                                backgroundColor: '#FED2492e',
                                padding: '12px 5px',
                                ":hover": {
                                  'backgroundColor': '#fed1495b',
                                  border: '2px solid #FED249'
                                }
                              }}
                            >Get Code
                            </Button>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} marginBottom="15px">
                        <Button
                          type="submit"
                          disabled={verifyOtpLoading}
                          variant="inherint"
                          size="large"
                          fullWidth
                          sx={{
                            color: '#fff',
                            backgroundColor: '#22BB00',
                            padding: '8px 10px',
                            fontWeight: '500',
                            ":hover": {
                              backgroundColor: '#1d9e00'
                            }
                          }}
                        >Verify</Button>
                      </Grid>
                    </Grid>
                  </Form>
                )}
              </CardContent>
            </Card>
          )}
          <Typography variant="body2" textAlign="center" mt={2} mb={6}>
            Contact Admin: &nbsp;
            <Link href={`tel:${process.env.REACT_APP_VIBER_NUMBER}`} color="inherit" underline="hover">
              {process.env.REACT_APP_VIBER_NUMBER}
            </Link>
          </Typography>
        </Box>
      </Container>
    </UnverifiedLayout >
  );
}
