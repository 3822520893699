import { differenceInHours, differenceInMilliseconds } from 'date-fns';
import addMinutes from 'date-fns/esm/fp/addMinutes/index.js';
import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';


const renderer = ({ completed, formatted, props: { completedMessage } }) => {
  if (completed) {
    return <span>{completedMessage}</span>;
  } else {
    return <span>{formatted.hours}:{formatted.minutes}:{formatted.seconds}</span>;
  }
};
// startTimerBefore: minutes
export default function RoundCountdown({ date, startTimerBefore = 60, onComplete, completedMessage = 'Closed' }) {
  const [startTimer, setStartTimer] = useState(false);

  const hoursDifference = differenceInHours(
    new Date(date),
    new Date(),
  );

  useEffect(() => {
    // setTimeout only if hour is less than 6
    // also check if hour less than 0, hoursDifference return -0 if hour less than 0
    if (Object.is(hoursDifference, -0) || hoursDifference > 6) {
      return false;
    }

    // Let countdown start 60 minutes before due time
    const runTimeoutIn = differenceInMilliseconds(
      addMinutes(-startTimerBefore, new Date(date)),
      new Date(),
    );

    const timeOut = setTimeout(() => {
      setStartTimer(true);
    }, runTimeoutIn);

    return () => {
      clearTimeout(timeOut);
    };
  }, [startTimerBefore, date, hoursDifference]);

  return startTimer ? (
    <Countdown
      onComplete={onComplete}
      overtime={false}
      daysInHours={true}
      date={date}
      completedMessage={completedMessage}
      renderer={renderer}
    />
  ) : `${hoursDifference} hrs`;
}
