import React from 'react';
import { Box, Card, CardContent, Container, Grid, Paper, Typography } from '@mui/material';

import useQuery from '@lottoasean/query-hook';

import PrivateLayout from "layouts/material-private";

import { fetchLottos } from 'apiRequest/lotto';

export default function WinList() {
  const { loading, error, payload: lottos } = useQuery(fetchLottos, { params: { partials: ['last_win_round.wins.number', 'last_win_round.wins.variety'] } });
  return (
    <PrivateLayout loading={loading} error={error}>
      <Box
        sx={{
          maxHeight: '200px',
          backgroundColor: 'rgb(254,210,74)',
          backgroundImage: 'linear-gradient(180deg, rgba(254,210,74,1) 0%, rgba(229,94,22,1) 100%)',
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom component="div" sx={{
                mt: { md: 5, xs: 3.5 },
                fontSize: '30px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                marginBottom: '30px',
                color: '#fff',
              }} >
                Rewards
              </Typography>
            </Grid>

            {lottos?.data.map((lotto) => (
              <Grid key={lotto.id} item xs={12} mb={4}>
                <Card elevation={4}>
                  <CardContent>
                    <Box mt={2}>
                      <Typography variant="button" my={3} component="div"
                        sx={{
                          fontSize: '20px',
                          fontWeight: '600',
                          textAlign: 'center',
                        }}
                      >
                        {lotto?.title} - {lotto.last_win_round?.title}
                      </Typography>
                    </Box>
                    <Grid container item spacing={3} md={12} justifyContent="center">
                      {lotto.last_win_round?.wins.map((win) => (
                        <Grid key={win.id} item md={3} xs={12}>
                          <Paper
                            sx={{
                              textAlign: 'center',
                              boxShadow: 'none',
                              border: `1px solid ${lotto.color}`
                            }}
                          >
                            <Box sx={{
                              padding: '6px 0px',
                              fontSize: '20px',
                              fontWeight: '600',
                              color: '#fff',
                              bgcolor: lotto.color,
                            }}>
                              {win.variety.title}
                            </Box>
                            <Box sx={{
                              padding: '6px 0px',
                            }}>
                              {win.number.number}
                            </Box>
                          </Paper>
                        </Grid>
                      ))}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Box>
    </PrivateLayout >
  );
}
