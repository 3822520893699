import React from 'react';
import { Redirect } from 'react-router';
import { Box } from '@mui/material';

import useQuery from '@lottoasean/query-hook';

import Nav from './Nav';
import LoadingProgressBar from 'components/LoadingProgressBar';
import Maintenance from 'components/Maintenance';

import { fetchUser } from 'apiRequest/user';

export default function PublicLayout({ loading, children }) {
  const { userLoading, error: userError, payload: user } = useQuery(fetchUser);

  if (!userLoading && user?.data.id) {
    return <Redirect to="/" />;
  }

  if (!userLoading && userError?.status === 402) {
    return <Redirect to="/verification" />;
  }

  if (!userLoading && userError?.status === 503) {
    return <Maintenance />;
  }

  return (
    <>
      <Nav />
      <Box position="absolute" width="100%">
        <LoadingProgressBar show={loading} />
      </Box>
      {children}
    </>
  );
}
