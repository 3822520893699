import * as Yup from 'yup';
import YupPassword from 'yup-password';
YupPassword(Yup);

const userRegisterValidation = Yup.object().shape({
  name: Yup.string().required(),
  phone_number: Yup.string().min(9).required(),
  username: Yup.string().min(3).strict().lowercase().matches(/^[a-z,0-9]+$/, 'The username must only contain letters and numbers.').required(),
  password: Yup.string().min(8).password().required(),
});

export default userRegisterValidation;
