import React from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { Box, Button, Card, CardContent, Container, Grid, Typography } from '@mui/material';

export default function Error404() {
  return (
    <Box
      sx={{
        maxHeight: '200px',
        backgroundColor: 'rgb(254,210,74)',
        backgroundImage: 'linear-gradient(180deg, rgba(254,210,74,1) 0%, rgba(229,94,22,1) 100%)',
      }}
    >
      <Container>
        <Grid container pt={{ md: 14, xs: 8 }}>
          <Grid item xs={12}>
            <Card elevation={4}>
              <CardContent>
                <Box textAlign="center" my={15}>
                  <Typography variant="h1" fontSize={{ md: '200px', xs: '8rem' }} fontWeight="bold" >404 </Typography>
                  <Typography variant="subtitle2" mb={4}>The page you are looking for does not exist.</Typography>
                  <Button
                    to="/"
                    component={RouteLink}
                    variant="outlined"
                    sx={{
                      color: '#333',
                      borderColor: '#333',
                      width: '150px',
                      ':hover': {
                        borderColor: '#333',
                      }
                    }}>Back to Home</Button>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box >
  );
}
