import React from 'react';
import { useHistory } from 'react-router-dom';
import { Box, Card, CardContent, Container, Grid, Skeleton, Typography } from '@mui/material';

import useMutation from '@lottoasean/mutation-hook';

import UpdatePasswordForm from 'components/forms/UpdatePasswordForm';
import UpdateProfileForm from 'components/forms/UpdateProfileForm';
import PrivateLayout from "layouts/material-private";

import { fetchUser, updateUser, updateUserPassword } from 'apiRequest/user';
import useQuery from '@lottoasean/query-hook';

export default function SettingsProfile() {

  const history = useHistory();
  const { loading, error, payload: user } = useQuery(fetchUser);
  const { loading: updateUserLoading, error: updateUserError, mutate: updateUserMutate } = useMutation(updateUser);
  const { loading: updateUserPasswordLoading, error: updateUserPasswordError, mutate: updateUserPasswordMutate } = useMutation(updateUserPassword);

  const handleUserUpdateSubmit = async (data) => {
    const submitData = {
      ...data,
    };

    const { error: mutateError } = await updateUserMutate({ data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      alert("Update Profile Success");
      history.push('/');
    }

  };

  const handleUserPasswordUpdateSubmit = async (data) => {
    const submitData = {
      ...data,
    };

    const { error: mutateError } = await updateUserPasswordMutate({ data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      alert("Update Password Success");
      if (submitData.revoke) {
        history.push('/login');
      } else {
        history.push('/');
      }
    }
  };

  return (
    <PrivateLayout error={error} loading={loading}>
      <Box
        sx={{
          maxHeight: '200px',
          backgroundColor: 'rgb(254,210,74)',
          backgroundImage: 'linear-gradient(180deg, rgba(254,210,74,1) 0%, rgba(229,94,22,1) 100%)',
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom component="div" sx={{
                mt: { md: 5, xs: 3.5 },
                fontSize: '30px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                marginBottom: '30px',
                color: '#fff',
              }} >
                Profile
              </Typography>
            </Grid>
            <Grid item xs={12} mb={4}>
              <Card elevation={4}>
                <CardContent>
                  {loading ? (
                    <Box sx={{ width: '100%' }}>
                      <Skeleton />
                      <Skeleton animation="wave" />
                      <Skeleton animation="wave" variant="rectangular" height={150} />
                      <Skeleton animation="wave" />
                    </Box>
                  ) : (
                    <UpdateProfileForm
                      initialValues={{ name: user?.data.name }}
                      onSubmit={handleUserUpdateSubmit}
                      loading={updateUserLoading}
                      serverError={updateUserError}
                    />
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} mb={8}>
              <Card elevation={4}>
                <CardContent>
                  <UpdatePasswordForm
                    onSubmit={handleUserPasswordUpdateSubmit}
                    serverError={updateUserPasswordError}
                    loading={updateUserPasswordLoading}
                  />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>

    </PrivateLayout>
  );
}
