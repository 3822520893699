import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Container, Box, Divider, Grid, Paper, Typography, Link } from '@mui/material';

import isEmpty from "lodash.isempty";
import dateTime from '@lottoasean/datetime-utils';
import useQuery from '@lottoasean/query-hook';

import PrivateLayout from "layouts/material-private";
import bgImage from "images/bg.png";

import { fetchLottos } from "apiRequest/lotto";
import RoundCountdown from 'components/RoundCountdown';

export default function LottoList() {
  const { loading, error, payload: lottos, query } = useQuery(fetchLottos, { params: { partials: ['activeRounds', 'upcomingRound'] } });

  const handleOnCountdownComplete = () => {
    setTimeout(() => {
      query();
    }, 1000);
  };


  return (
    <PrivateLayout loading={loading} error={error}>
      <Container
        sx={{
          display: "flex",
          marginBottom: "64px",
          '&::before': {
            content: '""',
            background: `url(${bgImage}) center center no-repeat`,
            backgroundSize: 'cover',
            height: '65vh',
            width: '100%',
            clipPath: 'polygon(0 0, 100% 0, 100% 100%, 0 70%)',
            position: 'absolute',
            left: '0',
            right: '0',
            zIndex: '-1',
          }
        }}
      >
        <Grid container spacing={3.5}>
          <Grid item xs={12}>
            <Typography gutterBottom component="div" sx={{
              fontSize: '40px',
              fontWeight: '600',
              textTransform: 'uppercase',
              color: '#fff',
              textAlign: 'center',
              textShadow: '0px 3px 6px rgba(0,0,0,0.16)',
              mt: { md: 11, xs: 7 }, mb: { md: 8, xs: 4 }
            }} >
              Win Big with Lotto Asean
            </Typography>
          </Grid>

          {lottos?.data.map((lotto) => {
            const isRoundEmpty = (isEmpty(lotto.active_rounds) && lotto.upcoming_round === null);
            return (
              <Grid key={lotto.id} item xs={12} sm={6} md={4}>
                <Paper elevation={4} sx={{ p: "16px", height: "100%", bgcolor: lotto.color }}>
                  <Grid container spacing={2} columns={12} sx={{ height: isRoundEmpty ? '100%' : 'auto' }}>
                    <Grid item xs={12} sx={{ display: 'flex' }}>
                      <Typography component="span"
                        sx={{
                          fontSize: '20px',
                          marginRight: '9px',
                          lineHeight: '21px',
                          color: '#fff',
                        }}
                      ></Typography>
                      <Typography variant="h4"
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          textTransform: 'uppercase',
                          color: '#fff',
                        }}
                      >{lotto.title}</Typography>
                    </Grid>
                    {isRoundEmpty ? (
                      <Grid item xs={12} gap={2} sx={{ height: '100%' }}>
                        <Paper elevation={3} sx={{
                          width: '100%',
                          height: '85%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          padding: '16px',
                          opacity: '0.75',
                          cursor: 'not-allowed',
                        }}>
                          <Typography variant="h5">Round Closed!</Typography>
                        </Paper>
                      </Grid>
                    ) : (
                      <Grid item xs={12} gap={2}>
                        {!isEmpty(lotto.active_rounds) && lotto.active_rounds.map((activeRound, index) => (
                          <Link key={activeRound.id} to={`/rounds/${activeRound.id}`} underline="none" component={RouteLink}
                            sx={{
                              '&>div:hover': {
                                boxShadow: '0px 0px 7px 0px rgba(255, 255, 255, 0.7)',
                              }
                            }}
                          >
                            <Paper elevation={3} sx={{
                              p: "16px",
                              marginTop: index !== 0 ? '15px' : 0,
                            }}>
                              <Box sx={{
                                width: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between'
                              }}>
                                <Typography sx={{ fontSize: 15, fontWeight: 600 }}>{`Round - ${activeRound.title}`}</Typography>
                                <Typography
                                  sx={{
                                    width: '65px',
                                    fontSize: '13px',
                                    padding: '3px 5px',
                                    borderRadius: '50px',
                                    color: '#fff',
                                    textAlign: 'center',
                                    backgroundColor: '#2BBE0A'
                                  }}
                                ><RoundCountdown date={activeRound.closed_at} startTimerBefore={60} onComplete={handleOnCountdownComplete} /></Typography>
                              </Box>
                              <Divider sx={{ margin: '5px 0px', borderStyle: 'dashed', borderColor: '#B4B4B4' }} />
                              <Typography sx={{ fontSize: 13 }}>Open at:  {dateTime.format(activeRound.opened_at)}</Typography>
                              <Typography sx={{ fontSize: 13 }}>Close at:  {dateTime.format(activeRound.closed_at)}</Typography>
                            </Paper>
                          </Link>
                        ))}


                        {lotto.upcoming_round !== null && (
                          <Paper
                            elevation={3} sx={{
                              marginTop: !isEmpty(lotto.active_rounds) ? '15px' : 0,
                              p: "16px",
                              opacity: '0.75',
                              cursor: 'not-allowed',
                            }}>
                            <Box sx={{
                              width: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between'
                            }}>
                              <Typography sx={{ fontSize: 15, fontWeight: 600 }}>Round - {lotto.upcoming_round.title}</Typography>
                              <Typography
                                sx={{
                                  width: '65px',
                                  fontSize: '13px',
                                  padding: '3px 5px',
                                  borderRadius: '50px',
                                  color: '#fff',
                                  textAlign: 'center',
                                  backgroundColor: '#FED24A'
                                }}
                              ><RoundCountdown
                                  date={lotto.upcoming_round.opened_at}
                                  startTimerBefore={60}
                                  onComplete={handleOnCountdownComplete}
                                  completedMessage={'Opening'}
                                />
                              </Typography>
                            </Box>
                            <Divider sx={{ margin: '5px 0px', borderStyle: 'dashed', borderColor: '#B4B4B4' }} />
                            <Typography sx={{ fontSize: 13 }}>Open at:  {dateTime.format(lotto.upcoming_round.opened_at)}</Typography>
                            <Typography sx={{ fontSize: 13 }}>Close at:  {dateTime.format(lotto.upcoming_round.closed_at)}</Typography>
                          </Paper>
                        )}
                      </Grid>
                    )}

                  </Grid>
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      </Container>
    </PrivateLayout>
  );
}
