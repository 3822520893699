import React from 'react';
import { Link as RouteLink, NavLink } from "react-router-dom";
import {
  AccountCircleOutlined as AccountCircleOutlinedIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from '@mui/icons-material';
import { AppBar, Button, Box, Container, Divider, Menu, MenuItem, Stack, Toolbar, Typography, Link } from '@mui/material';

import currency from "@lottoasean/currency-utils";

import viberLogo from 'images/viber-logo.svg';
import MobileNav from './MobileNav';
import mainMenu from './mainMenu.json';

export default function Nav({ user, onLogout, onLogoutLoading }) {

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar
      position="relative"
      color="inherit"
    >
      <Toolbar disableGutters>
        <Container sx={{ display: 'flex' }}>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              width: '100%',
              height: { md: '66px', xs: '51px' },
              marginTop: '10px',
              marginBottom: '10px',
            }}
          >
            <MobileNav user={user} onLogout={onLogout} onLogoutLoading={onLogoutLoading} />
            <Box sx={{
              display: { xs: 'none', md: 'flex' },
              flexWrap: 'wrap',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%'
            }}>
              <Box>
                {mainMenu.map(({ label, url }) => (
                  <Button
                    key={url}
                    color="inherit"
                    to={url}
                    component={NavLink} activeStyle={{ background: "rgb(254 215 83)" }}
                    sx={{
                      fontSize: "15px",
                      color: '#333333',
                      fontWeight: '500',
                      textTransform: 'uppercase',
                      padding: '6px 18px',
                      ":hover": {
                        bgcolor: "rgb(254 215 83)"
                      }
                    }}
                  >
                    {label}
                  </Button>
                ))}
              </Box>

              <Box>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Box sx={{
                    color: '#333',
                    textAlign: 'center',
                    fontSize: '14px',
                    fontWeight: 500,
                    minWidth: '95px',
                    padding: '8px 10px',
                    borderRadius: '50px',
                    backgroundColor: '#FED249'
                  }}>
                    {currency.format(user?.data.unit)}
                  </Box>
                  <Button
                    variant="inherint"
                    onClick={handleClick}
                    endIcon={<ArrowDropDownIcon />}
                    sx={{
                      textTransform: "unset",
                      fontWeight: 500,
                      color: '#333'
                    }}
                  >
                    <AccountCircleOutlinedIcon sx={{ fontSize: 30, marginRight: 0.5 }} />
                    {user?.data.name}
                  </Button>
                  <Menu
                    elevation={0}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    disableScrollLock={true}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    PaperProps={{
                      sx: {
                        borderRadius: '6px',
                        marginTop: 1,
                        minWidth: 150,
                        color: 'rgb(55, 65, 81)',
                        boxShadow:
                          'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
                      }
                    }}
                  >
                    <MenuItem
                      sx={{ fontSize: 14 }}
                      component={RouteLink}
                      to="/settings/profile"
                    >
                      Profile
                    </MenuItem>
                    <Divider />
                    <MenuItem
                      sx={{ fontSize: 14 }}
                      onClick={onLogout} disabled={onLogoutLoading}
                    >
                      Logout
                    </MenuItem>
                    <Divider />
                    <Typography variant="button" px={2} component="div">
                      Deposit/Withdraw
                    </Typography>
                    <MenuItem>
                      <img src={viberLogo} alt="viber" />
                      <Typography variant="body2" sx={{ marginLeft: '8px' }}>
                        <Link href={`tel:${process.env.REACT_APP_VIBER_NUMBER}`} underline="none" color="inherit">
                          {process.env.REACT_APP_VIBER_NUMBER}
                        </Link>
                      </Typography>
                    </MenuItem>
                  </Menu>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Container>
      </Toolbar>
    </AppBar>
  );
}
