import React from 'react';

import { AccountCircleOutlined as AccountCircleOutlinedIcon, Menu as MenuIcon } from '@mui/icons-material';
import { IconButton, Box, Link, Drawer, List, ListItem, ListItemText, Divider, Typography, Chip } from '@mui/material';

import currency from "@lottoasean/currency-utils";

import lottoLogo from 'images/lotto-logo-large.png';
import viberLogo from 'images/viber-logo.svg';
import mainMenu from './mainMenu.json';

export default function MobileNav({ user, onLogout, onLogoutLoading }) {
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const handleDrawerToggle = () => { setMobileOpen(!mobileOpen); };

  return (
    <>
      <IconButton
        color="inherit"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ display: { xs: 'flex', md: 'none' }, mr: 2 }}
      >
        <MenuIcon />
      </IconButton>
      <img src={lottoLogo} alt="logo" style={{
        height: '100%',
        marginRight: '20px'
      }} />
      <Box sx={{ display: { xs: 'flex', md: 'none' }, marginLeft: 'auto' }} >
        <Chip label={currency.format(user?.data.unit)} color="primary"
          sx={{
            background: '#FED249',
            color: '#000'
          }}
        />
      </Box>

      <Drawer
        anchor="left"
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{ display: { sm: 'block', md: 'none' } }}
      >
        <Box sx={{
          padding: '32px 16px 18px;',
          width: 240,
          boxSizing: 'border-box'
        }}>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
          }}>
            <AccountCircleOutlinedIcon sx={{ fontSize: 30, marginRight: 0.5 }} />
            {user?.data.name}
          </Box>
          <Box sx={{
            color: '#333',
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 500,
            padding: '8px 10px',
            marginTop: '10px',
            borderRadius: '50px',
            backgroundColor: '#FED249'
          }}>
            {currency.format(user?.data.unit)}
          </Box>
        </Box>

        <List
          sx={{
            fontSize: "14px",
            textTransform: "uppercase"
          }}
        >
          <Divider sx={{ margin: '10px 0px' }} />
          {mainMenu.map(({ label, url }) => (
            <ListItem button key={url} disabled>
              <ListItemText>
                {label}
              </ListItemText>
            </ListItem>
          ))}
          <Divider sx={{ margin: '10px 0px' }} />
          <ListItem button disabled>
            <ListItemText>
              Profile
            </ListItemText>
          </ListItem>
          <ListItem button onClick={onLogout} disabled={onLogoutLoading}>
            <ListItemText>
              Logout
            </ListItemText>
          </ListItem>
          <Divider sx={{ margin: '10px 0px' }} />
          <ListItem >
            <Typography variant="button">
              Deposit/Withdraw
            </Typography>
          </ListItem>
          <ListItem button>
            <img src={viberLogo} alt="viber" />
            <Typography variant="body2" sx={{ marginLeft: '8px' }}>
              <Link href={`tel:${process.env.REACT_APP_VIBER_NUMBER}`} underline="none" color="inherit">
                {process.env.REACT_APP_VIBER_NUMBER}
              </Link>
            </Typography>
          </ListItem>
        </List>
      </Drawer>
    </>
  );
}
