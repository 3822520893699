import fetch from "@lottoasean/fetch-utils";

export const fetchUser = ({ token, params }) => {
  return fetch({
    url: 'user',
    method: "GET",
    params,
    token
  });
};

export const updateUser = ({ token, data }) => {
  return fetch({
    url: 'user',
    method: "PUT",
    data,
    token
  });
};

export const updateUserPassword = ({ token, data }) => {
  return fetch({
    url: 'user/password',
    method: "PUT",
    data,
    token
  });
};

export const logoutUser = ({ token }) => {
  return fetch({
    url: 'user/logout',
    method: "POST",
    token
  });
};

export const createUser = ({ token, data }) => {
  return fetch({
    token,
    url: `user`,
    method: "POST",
    data
  });
};
