import React from 'react';
import { useHistory, Redirect } from "react-router-dom";
import { Box } from '@mui/material';

import useToken from '@lottoasean/token-hook';
import useQuery from '@lottoasean/query-hook';
import useMutation from '@lottoasean/mutation-hook';

import Nav from './Nav';

import { fetchVerificationUser } from 'apiRequest/verification';
import { logoutUser } from 'apiRequest/user';
import LoadingProgressBar from 'components/LoadingProgressBar';
import Maintenance from 'components/Maintenance';

export default function UnverifiedLayout({ children, error, loading }) {
  const history = useHistory();
  const { removeToken } = useToken();

  const { loading: userLoading, payload: user } = useQuery(fetchVerificationUser);

  const { loading: logoutUserLoading, mutate } = useMutation(logoutUser);

  const handleLogout = async () => {
    const { error } = await mutate();
    if (error) {
      console.log(error);
      alert("something was wrong");
    } else {
      removeToken();
      history.push('/login');
    }
  };

  if (error?.status === 503) {
    return <Maintenance />;
  }

  if (error?.status === 401) {
    removeToken();
    return <Redirect to="/login" />;
  }

  if (!userLoading && user?.data.verified_at != null) {
    return <Redirect to="/" />;
  }


  return (
    <>
      <Nav user={user} onLogout={handleLogout} onLogoutLoading={logoutUserLoading} />
      <Box position="absolute" width="100%">
        <LoadingProgressBar show={loading || userLoading || logoutUserLoading} />
      </Box>
      {children}
    </>
  );
}
