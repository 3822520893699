import React from 'react';
import { Form } from 'informed';
import { Button, Grid, Typography } from '@mui/material';

import TextInput from 'components/TextInput';

export default function UpdateProfileForm({ onSubmit, loading, initialValues }) {
  return (
    <Form onSubmit={onSubmit} initialValues={initialValues}>
      {() => (
        <Grid container item spacing={2} md={5} mx={{ md: 'auto' }}>
          <Grid item >
            <Typography variant="button" my={3} component="div"
              sx={{
                fontSize: '20px',
                fontWeight: '600',
                textAlign: 'center',
              }}
            >
              Update Profile
            </Typography>
          </Grid>
          <Grid item xs={12} >
            <TextInput field="name" label="Name" fullWidth required />
          </Grid>
          <Grid item xs={12} marginBottom="15px">
            <Button
              type="submit"
              variant="inherint"
              size="large"
              disabled={loading}
              sx={{
                width: '120px',
                color: '#333',
                backgroundColor: '#FED249',
                padding: '8px 10px',
                fontWeight: '500',
                ":hover": {
                  backgroundColor: '#f9ca39'
                }
              }}
            >Update</Button>
          </Grid>
        </Grid>
      )}
    </Form>
  );
}
