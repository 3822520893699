import React from 'react';
import { useField } from 'informed';
import { FormControlLabel, Checkbox } from '@mui/material';

export default function CheckboxInput(props) {
  const { fieldState, fieldApi, render, ref, userProps } = useField(props);
  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, ...rest } = userProps;

  return render(
    <React.Fragment>
      <FormControlLabel
        {...rest}
        ref={ref}
        control={
          <Checkbox
            value={!value && value !== 0 ? '' : value}
            onChange={e => {
              setValue(e.target.checked);
              if (onChange) {
                onChange(e);
              }
            }}
            onBlur={e => {
              setTouched(true);
              if (onBlur) {
                onBlur(e);
              }
            }}
          />
        }
      />

    </React.Fragment>
  );
};
