import React from 'react';
import { Route, Switch } from 'react-router-dom';

import PrivateRoute from 'components/PrivateRoute';

import Login from "pages/login";
import LottoList from "pages/lottos/list";
import RoundShow from "pages/rounds/show";
import TransactionList from "pages/transactions/list";
import BetList from "pages/bets/list";
import BetShow from "pages/bets/show";
import RewardList from 'pages/rewards/list';
import SettingsProfile from 'pages/settings/profile';
import Register from 'pages/register';
import Verification from 'pages/verification';
import NotFound from 'pages/NotFound';

export default function Routes() {
  return (
    <Switch>
      <Route exact path="/login">
        <Login />
      </Route>
      <Route exact path="/register">
        <Register />
      </Route>
      <Route exact path="/verification">
        <Verification />
      </Route>
      <PrivateRoute exact path={["/lottos", "/"]}>
        <LottoList />
      </PrivateRoute>
      <PrivateRoute exact path="/rounds/:id">
        <RoundShow />
      </PrivateRoute>
      <PrivateRoute exact path="/transactions">
        <TransactionList />
      </PrivateRoute>
      <PrivateRoute exact path="/bets">
        <BetList />
      </PrivateRoute>
      <PrivateRoute exact path="/bets/:id">
        <BetShow />
      </PrivateRoute>
      <PrivateRoute exact path="/rewards">
        <RewardList />
      </PrivateRoute>
      <PrivateRoute exact path="/settings/profile">
        <SettingsProfile />
      </PrivateRoute>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
