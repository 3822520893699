import fetch from "@lottoasean/fetch-utils";

export const fetchRounds = ({ params, ...rest }) => {
  return fetch({
    url: 'rounds',
    method: "GET",
    params,
    ...rest
  });
};

export const fetchRound = ({ id, params, ...rest }) => {
  return fetch({
    url: `rounds/${id}`,
    method: "GET",
    params,
    ...rest
  });
};
