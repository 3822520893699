import React from 'react';
import { Link as RouteLink } from "react-router-dom";
import { Box, AppBar, Button, Container, Stack, Toolbar } from '@mui/material';

import lottoLogo from 'images/lotto-logo-large.png';

export default function Nav() {
  return (
    <Box>
      <AppBar
        position="relative"
        color="inherit"
      >
        <Toolbar disableGutters>
          <Container>
            <Box
              variant="permanent"
              sx={{
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                height: { md: '60px', xs: '45px' },
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <img src={lottoLogo} alt="logo" style={{ height: '100%', marginRight: '20px' }} />
              <Box sx={{ marginLeft: 'auto' }} >
                <Stack direction="row" spacing={1}>
                  <Button to="/register" color="inherit" disableElevation component={RouteLink}
                    sx={{
                      width: '90px',
                      color: '#333',
                      backgroundColor: '#FED249',
                      padding: '8px 10px',
                      fontWeight: '500',
                      ":hover": {
                        bgcolor: '#f9ca39'
                      }
                    }}
                  >
                    Register
                  </Button>
                  <Button to="/login" color="inherit" disableElevation component={RouteLink}
                    sx={{
                      width: '90px',
                      color: '#fff',
                      backgroundColor: '#22BB00',
                      padding: '8px 10px',
                      fontWeight: '500',
                      ":hover": {
                        bgcolor: '#1d9e00'
                      }
                    }}
                  >
                    Login
                  </Button>
                </Stack>
              </Box>
            </Box>
          </Container>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
