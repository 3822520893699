import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Container, Box, Grid, Typography, TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Tabs, Tab, Card, CardContent, Skeleton } from '@mui/material';

import Countdown from 'react-countdown';
import isEmpty from 'lodash.isempty';
import MDEditor from '@uiw/react-md-editor';
import find from 'lodash.find';
import useQuery from '@lottoasean/query-hook';
import currency from '@lottoasean/currency-utils';
import useMutation from '@lottoasean/mutation-hook';

import PrivateLayout from "layouts/material-private";
import CreateBetForm from 'components/forms/CreateBetForm';

import { createBet } from 'apiRequest/bet';
import { fetchRound } from "apiRequest/round";

function MyCountdown({ date, onComplete }) {
  return (
    <Countdown
      onComplete={onComplete}
      date={new Date(date)}
      renderer={({ formatted }) => (<span>{formatted.days}Day : {formatted.hours}Hr : {formatted.minutes}Min : {formatted.seconds}Sec</span>)}
    />
  );
}

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div hidden={value !== index}>
      {value === index && (
        <Box sx={{ p: { md: 1, xs: 0 }, }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function RoundShow() {
  const { id } = useParams();
  const [activeVariety, setActiveVariety] = useState(false);
  const history = useHistory();

  const { loading, error, payload: round } = useQuery(fetchRound, { id, params: { partials: ['lotto', 'lotto.varieties', 'lotto.varieties.first_number'] } });
  const { loading: mutateLoading, error: mutateError, mutate } = useMutation(createBet);

  const handleTabChange = (event, newValue) => {
    setActiveVariety(find(round.data.lotto.varieties, { id: newValue }));
  };

  useEffect(() => {
    if (!isEmpty(round?.data.lotto.varieties)) {
      setActiveVariety(round.data.lotto.varieties[0]);
    }
  }, [round?.data.lotto.varieties]);

  const handleOnSubmit = async ({ variety_id, bets }) => {
    const submitData = {
      bets: bets.filter((bet) => bet !== null),
      variety_id,
      lotto_id: round.data.lotto.id,
      round_id: round.data.id,
    };

    const { payload: mutatePayload, error: mutateError } = await mutate({ data: submitData });

    if (mutateError && mutateError.status !== 422) {
      alert(`API ERROR:(${mutateError.status} ${mutateError.statusText})`);
      console.error(mutateError);
    }

    if (!mutateError) {
      history.push(`/bets/${mutatePayload.data.id}`);
    }
  };

  const handleOnCountdownComplete = () => {
    history.push(`/lottos`);
  };

  return (
    <PrivateLayout loading={loading} error={error}>
      <Box sx={{
        height: { md: '388px', xs: '480px' },
        bgcolor: round?.data.lotto.color
      }}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom component="div" sx={{
                mt: { md: 5, xs: 3.5 },
                fontSize: '30px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                marginBottom: '30px',
                color: '#fff',
              }} >
                {round?.data.lotto.title}
              </Typography>
            </Grid>
            {loading ? (
              <Box sx={{ width: '100%' }}>
                <Skeleton />
                <Skeleton animation="wave" />
                <Skeleton />
                <Skeleton animation="wave" variant="rectangular" height={150} />
                <Skeleton animation="wave" />
              </Box>
            ) : (
              round?.data.is_active ? (
                <>
                  <Grid item xs={12} md={6} mb={4}>
                    <Typography variant="button" sx={{ fontSize: '16px', color: '#fff' }}>Round </Typography>
                    <Typography sx={{
                      fontSize: '25px',
                      marginBottom: '0',
                      color: '#fff',
                      fontWeight: 'bold',
                      textTransform: 'uppercase',
                    }} >
                      {round?.data.title}
                    </Typography>
                    <Typography sx={{
                      fontSize: '18px',
                      fontWeight: '500',
                      color: '#fff'
                    }}>
                      <Typography component="span" sx={{ fontSize: '14px' }}>ကျန်ရှိအချိန်</Typography>&nbsp;-&nbsp;
                      <MyCountdown date={round?.data.closed_at} onComplete={handleOnCountdownComplete} />
                    </Typography>
                    <Typography variant="body2"
                      sx={{ color: '#fff' }} mb={1} mt={5}>
                      ကြိုက်နှစ်သက်သောဂဏန်း {activeVariety.digit} လုံးကိုရွေးပြီး လောင်းကြေးထည့်ပေးပါ။
                    </Typography>
                    <TableContainer>
                      <Table
                        sx={{
                          width: '320px',
                          height: '70px',
                          borderCollapse: 'separate',
                          borderSpacing: '2px',
                          '& tr': {
                            bgcolor: 'white',
                          },
                          '& th': {
                            fontWeight: 600,
                          },
                          '& th, & td': {
                            padding: '8px',
                            textAlign: 'center'
                          }
                        }}
                      >
                        <TableHead>
                          <TableRow >
                            <TableCell>အမျိုးအစား</TableCell>
                            <TableCell>အနည်းဆုံး</TableCell>
                            <TableCell>အများဆုံး</TableCell>
                            <TableCell>လျော်ဆ</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell>
                              {activeVariety.title}
                            </TableCell>
                            <TableCell>{currency.format(100)}</TableCell>
                            <TableCell>{currency.format(100000)}</TableCell>
                            <TableCell>{activeVariety.first_number.multiply}</TableCell>
                          </TableRow>

                        </TableBody>
                      </Table>
                    </TableContainer>

                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Box>
                      <Tabs
                        value={activeVariety.id}
                        onChange={handleTabChange}
                        variant="scrollable"
                        indicatorColor={null}
                      >
                        {round?.data.lotto.varieties.map((variety) => (
                          <Tab
                            key={variety.id}
                            value={variety.id}
                            label={variety.title}
                            sx={{
                              fontSize: '18px',
                              fontWeight: '600',
                              backgroundColor: '#EFEFEF',
                              marginRight: '4px',
                              borderTopLeftRadius: '4px',
                              borderTopRightRadius: '4px',
                              "&.Mui-selected": {
                                color: '#333',
                                backgroundColor: "#fff",
                                zIndex: 1
                              },
                            }}
                          />
                        ))}
                      </Tabs>
                    </Box>
                    <Card sx={{
                      position: 'relative',
                      borderTopLeftRadius: '0',
                      boxShadow: '0px 0px 8px 0px rgba(0,0,0,0.23)'
                    }}>
                      <CardContent>
                        {round?.data.lotto.varieties.map((variety) => (
                          <TabPanel key={variety.id} value={activeVariety.id} index={variety.id} >
                            <CreateBetForm
                              variety={variety}
                              onSubmit={handleOnSubmit}
                              loading={mutateLoading}
                              serverError={mutateError}
                            />
                          </TabPanel>
                        ))}
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} my={8} textAlign="center">
                    <MDEditor.Markdown source={activeVariety?.rule} />
                  </Grid>
                </>
              ) : (
                <Grid item xs={12} my={8} textAlign="center">
                  <Typography variant="h4" fontWeight="bold" mb={4} color="white">
                    ကစားလို့ မရနိုင်သေးပါ
                  </Typography>
                </Grid>
              )
            )}
          </Grid>
        </Container>
      </Box>
    </PrivateLayout>
  );
}
