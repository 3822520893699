import { useCallback, useState } from "react";
import useToken from "@lottoasean/token-hook";

export default function useMutation(action) {
  const { token } = useToken();

  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [payload, setPayload] = useState();

  const handleQuery = useCallback(
    async (specification) => {
      setLoading(true);

      try {
        const { data } = await action({ token, ...specification });
        setPayload(data);
        setLoading(false);
        return { payload: data };
      } catch ({ response }) {
        setError(response);
        setLoading(false);
        return { error: response };
      }
    },
    [token, action]
  );

  return {
    loading, error, payload, mutate: handleQuery
  };
}
