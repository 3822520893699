import React from 'react';
import { useField } from 'informed';
import { TextField } from '@mui/material';

export default function TextInput(props) {

  const { fieldState, fieldApi, render, ref, userProps } = useField(props);

  const { value } = fieldState;
  const { setValue, setTouched } = fieldApi;
  const { onChange, onBlur, ...rest } = userProps;
  return render(
    <React.Fragment>
      <TextField
        error={Boolean(fieldState.error)}
        helperText={fieldState.error}
        {...rest}
        ref={ref}
        value={!value && value !== 0 ? '' : value}
        onChange={e => {
          setValue(e.target.value);
          if (onChange) {
            onChange(e);
          }
        }}
        onBlur={e => {
          setTouched(true);
          if (onBlur) {
            onBlur(e);
          }
        }}
        sx={{
          "& .MuiInputLabel-root, & .MuiOutlinedInput-input": {
            fontSize: '14px'
          },
          "& .MuiInputLabel-shrink": {
            fontSize: '1rem'
          },
        }}
      />
    </React.Fragment>
  );
};
