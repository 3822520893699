import React from 'react';

import { Box, Card, CardContent, Container, Grid, Link, Typography } from '@mui/material';

export default function Maintenance() {
  return (
    <Box
      sx={{
        maxHeight: '200px',
        backgroundColor: 'rgb(254,210,74)',
        backgroundImage: 'linear-gradient(180deg, rgba(254,210,74,1) 0%, rgba(229,94,22,1) 100%)',
      }}
    >
      <Container>
        <Grid container pt={{ md: 14, xs: 8 }}>
          <Grid item xs={12}>
            <Card elevation={4}>
              <CardContent>
                <Box textAlign="center" my={15}>
                  <Typography variant="h1" mb={1} fontSize={{ md: '200px', sm: '8rem', xs: '6rem' }} fontWeight="bold">Oops!</Typography>
                  <Typography variant="subtitle2" fontSize={{ md: '25px', xs: '15px' }} mb={1}>Lotto ASEAN is under maintenance</Typography>
                  <Typography variant="subtitle2">Please come back later</Typography>
                  <Typography variant="body2" mt={3}>
                    Contact Admin: &nbsp;
                    <Link href={`tel:${process.env.REACT_APP_VIBER_NUMBER}`} color="inherit" underline="hover">
                      {process.env.REACT_APP_VIBER_NUMBER}
                    </Link>
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Container>
    </Box >
  );
}
