import AES from "crypto-js/aes";
import ENCUTF8 from "crypto-js/enc-utf8";
import { MD5 } from "crypto-js";

const crypto = {
  encrypt: (message) => {
    const key = process.env.REACT_APP_KEY;
    return AES.encrypt(message, key).toString();
  },
  
  decrypt: (cipherText) => {
    if (!cipherText) {
      return cipherText;
    }
    try {
      const key = process.env.REACT_APP_KEY;
      return AES.decrypt(cipherText, key).toString(ENCUTF8);
    } catch (err) {
      return null;
    }
  },

  md5: (message) => {
    return MD5(message).toString();
  }
};

export default crypto;
