import React, { forwardRef } from 'react';
import { Box, Divider, Grid, Table, TableBody, TableCell, TableFooter, TableHead, TableRow, Typography } from '@mui/material';

import { sumBy } from 'lodash';
import currency from '@lottoasean/currency-utils';
import dateTime from '@lottoasean/datetime-utils';

export default forwardRef(({ payload }, ref) => {
  const totalBetUnit = sumBy(payload?.bet_numbers, (b) => b.unit);

  return (
    <div style={{ position: "absolute", top: -1000, zIndex: -999999 }}>
      <Box
        ref={ref}
        sx={{ width: '340px', padding: '15px', backgroundColor: '#fff' }}>
        <Typography variant="subtitle2" gutterBottom component="div" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          {payload?.round.lotto.title} - {payload?.round.title} ({payload?.variety.title})
        </Typography>
        <Typography variant="subtitle2" gutterBottom component="div" sx={{ textAlign: 'center', fontWeight: 'bold' }}>
          www.lottoasean.com
        </Typography>
        <Grid container sx={{ justifyContent: 'center' }}>
          <Grid item fontSize="13px">
            <Typography variant="span">Bet ID:&nbsp;</Typography>
            <Typography variant="span" fontWeight="bold">{payload?.id}</Typography>
          </Grid>
          <Divider orientation="vertical" sx={{ margin: '0px 8px', borderStyle: 'dashed', borderColor: '#B4B4B4' }} flexItem />
          <Grid item fontSize="13px">
            <Typography variant="span">Bet Date:&nbsp;</Typography>
            <Typography variant="span" fontWeight="bold">{dateTime.format(payload?.created_at)}</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ marginTop: '16px ', borderBottom: '2px solid #bbb' }} />
        <Table
          sx={{
            'thead tr th': { borderBottom: '2px solid #bbb' },
            'tbody tr td': { padding: '10px 16px' },
            'tfoot tr td': { borderTop: '2px solid #bbb', color: "#040404", py: '10px', fontSize: '15px', borderBottom: 'none', backgroundColor: '#f5f5f5' },
          }}>
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell>Number</TableCell>
              <TableCell>Unit</TableCell>
              <TableCell>Mutliply</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payload?.bet_numbers?.map((bet_number, index) => (
              <TableRow key={bet_number.id}>
                <TableCell>{index + 1}</TableCell>
                <TableCell>{bet_number.number.number}</TableCell>
                <TableCell>{currency.format(bet_number.unit)}</TableCell>
                <TableCell>{bet_number.multiply}</TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TableCell />
              <TableCell colSpan={1} sx={{ fontWeight: 'bold' }}>Total</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>{currency.format(totalBetUnit)}</TableCell>
              <TableCell colSpan={2} />
            </TableRow>
          </TableFooter>
        </Table>
      </Box>
    </div>
  );
});;
