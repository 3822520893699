import React, { useEffect, useMemo } from 'react';
import { useFormState, Multistep, useMultistepApi, Text } from 'informed';

import sumBy from "lodash.sumby";
import merge from 'lodash.merge';
import currency from "@lottoasean/currency-utils";
import useMutation from '@lottoasean/mutation-hook';

import { fetchNumbers } from 'apiRequest/number';

import {
  Alert,
  AlertTitle,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Box,
  Typography
} from '@mui/material';

import { WarningAmber as WarningAmberIcon } from '@mui/icons-material';

export default function ConfirmCreateBetStep({ variety, serverError, loading }) {
  const { back, getCurrentStep } = useMultistepApi();
  const { values } = useFormState();

  const currentStepName = getCurrentStep()?.name;

  const total = sumBy(values.bets?.filter(b => b.unit), (b) => parseInt(b?.unit));

  const { loading: numbersLoading, payload: numbers, mutate: query } = useMutation(fetchNumbers);

  useEffect(() => {

    if (currentStepName === "confirm" && values.bets) {
      const numbers = values.bets?.filter((bet) => bet !== null).map(({ number }) => (number));
      query({ params: { filters: { number_in: numbers, variety_id: variety.id } } });
    }
  }, [values.bets, currentStepName, query, variety.id]);

  const data = useMemo(() => {
    if (values.bets && numbers?.data && numbersLoading === false) {
      const filterd = [...values?.bets?.filter((bet) => bet !== null)];
      return merge(filterd, numbers.data);
    }
    return [];
  }, [values, numbers?.data, numbersLoading]);

  const [dialogOpen, setDialogOpen] = React.useState(false);

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  return (
    <Multistep.Step step="confirm" previous="inputs">
      <Dialog
        open={dialogOpen}
        keepMounted
        onClose={handleDialogClose}
      >
        <DialogTitle><WarningAmberIcon sx={{ color: '#FF9800', verticalAlign: '-4px' }} /> Warning!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            This 75 number can't get bet above 500!
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: '16px' }}>
          <Button onClick={handleDialogClose}
            sx={{
              width: '120px',
              color: '#FFF',
              bgcolor: '#333',
              ":hover": {
                bgcolor: '#333',
              }
            }}
          >OK</Button>
        </DialogActions>
      </Dialog>

      <Text hidden field="variety_id" initialValue={variety.id} />
      {serverError?.data.errors['bets'] &&
        <Alert severity="error">
          {serverError.data.errors['bets']}
        </Alert>
      }
      <Box my={2}>
        <Alert icon={false} severity="info">
          <AlertTitle>Bet Confirmation</AlertTitle>
          Are you sure? Total amount would be <strong>{currency.format(total)}</strong>.
        </Alert>
      </Box>
      <TableContainer>
        <Box>
          <Table
            sx={{
              "& thead tr": {
                textTransform: 'uppercase',
                fontWeight: '600',
                backgroundColor: '#E4E0DD',
              },
              "& tbody tr:nth-of-type(odd)": {
                backgroundColor: '#F4F3F1'
              },
              "@media screen and (max-width: 480px)": {
                "thead tr th, tr td": {
                  padding: '10px'
                }
              }
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell>Number</TableCell>
                <TableCell>Unit</TableCell>
                <TableCell>Multiply</TableCell>
                <TableCell>Win Rate</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.map(({ id, number, unit, multiply }, index) => (
                <TableRow key={id}>
                  <TableCell>{number}</TableCell>
                  <TableCell>{currency.format(unit)}</TableCell>
                  <TableCell>{multiply}</TableCell>
                  <TableCell>{currency.format(unit * multiply)}</TableCell>
                  <TableCell>
                    {serverError &&
                      <Typography color="error">
                        {serverError?.data.errors[`bets.${index}.number`]}
                        {serverError?.data.errors[`bets.${index}.unit`]}
                      </Typography>
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </TableContainer>
      <Box mt={2}>
        <Button
          onClick={back}
          variant="outlined"
          sx={{
            marginRight: '8px',
            width: '120px',
            color: '#333',
            borderColor: '#333',
            ":hover": {
              borderColor: '#333',
            }
          }}
        >Back</Button>
        <Button
          type="submit"
          disabled={loading}
          variant="contained" disableElevation
          sx={{
            width: '120px',
            color: '#FFF',
            bgcolor: '#333',
            ":hover": {
              bgcolor: '#333',
            }
          }}
        >Submit</Button>

        {serverError && <Box>
          <Typography color="error" >{serverError.data?.message}</Typography>
        </Box>}
      </Box>
    </Multistep.Step>
  );
}
