import React from 'react';
import { Link as RouteLink } from 'react-router-dom';
import { Box, Card, CardContent, Container, Grid, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Link } from '@mui/material';

import { formatRelative, parseISO } from 'date-fns';
import useQuery from '@lottoasean/query-hook';

import PrivateLayout from "layouts/material-private";

import { fetchBets } from 'apiRequest/bet';

export default function BetList() {
  const { loading, error, payload: bets } = useQuery(fetchBets, { params: { partials: ['round', 'round.lotto', 'variety'] } });

  return (
    <PrivateLayout loading={loading} error={error}>
      <Box
        sx={{
          maxHeight: '200px',
          backgroundColor: 'rgb(254,210,74)',
          backgroundImage: 'linear-gradient(180deg, rgba(254,210,74,1) 0%, rgba(229,94,22,1) 100%)',
        }}
      >
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography gutterBottom component="div" sx={{
                mt: { md: 5, xs: 3.5 },
                fontSize: '30px',
                fontWeight: 'bold',
                textTransform: 'uppercase',
                marginBottom: '30px',
                color: '#fff',
              }} >
                Bets
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Card elevation={4}>
                <CardContent>
                  <TableContainer sx={{
                    paddingTop: "16px"
                  }}>
                    <Table
                      sx={{
                        "& thead tr": {
                          textTransform: 'uppercase',
                          fontWeight: '600',
                          backgroundColor: '#E4E0DD',
                        },
                        "& tbody tr:nth-of-type(odd)": {
                          backgroundColor: '#F4F3F1'
                        }
                      }}
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>ID</TableCell>
                          <TableCell>Title</TableCell>
                          <TableCell>Date</TableCell>
                          <TableCell/>

                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {bets?.data.map((bet) => {
                          return (
                            <TableRow key={bet.id}>
                              <TableCell>{bet.id}</TableCell>
                              <TableCell>{`${bet.round?.lotto.title} ${bet.variety.title} - ${bet.round?.title}`}</TableCell>
                              <TableCell>{formatRelative(parseISO(bet.created_at), new Date())}</TableCell>
                              <TableCell><Link component={RouteLink} to={`/bets/${bet.id}`}>Show</Link></TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </PrivateLayout>
  );
}
