import React from 'react';
import { ArrayField, Multistep, useFormState, useMultistepApi } from 'informed';
import { Box, Button, Grid, Typography } from '@mui/material';
import { DeleteForeverOutlined as DeleteForeverOutlinedIcon } from '@mui/icons-material';

import sumBy from "lodash.sumby";
import isString from "lodash.isstring";
import currency from "@lottoasean/currency-utils";

import TextInput from 'components/TextInput';
import FieldErrorMessage from '../FieldErrorMessage';

export default function EnterBetNumbersStep({ variety, betsLimit }) {
  const { next } = useMultistepApi();
  const { values } = useFormState();
  const total = sumBy(values.bets?.filter(b => b.unit), (b) => parseInt(b?.unit));

  return (
    <Multistep.Step step="inputs" next="confirm">
      <Box sx={{ marginBottom: "10px" }}>
        <FieldErrorMessage field={'bets'} format formatMethod={(value) => isString(value)} />
      </Box>
      <ArrayField field="bets">
        {({ add, reset, fields }) => (
          <>
            <Grid container spacing={1.5} mt={1}>
              <ArrayField.Items>
                {({ remove, field }) => (
                  <Grid container item spacing={1}>
                    <Grid item md={5} xs={5}>
                      <TextInput
                        inputProps={{
                          maxLength: variety.digit, minLength: variety.digit
                        }}
                        field={`${field}.number`}
                        label="Number"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={5} xs={5}>
                      <TextInput field={`${field}.unit`} label="Unit"
                        size="small"
                        fullWidth
                      />
                    </Grid>
                    <Grid item md={2} xs={2}>
                      <Button variant="contained" color="error" sx={{ minWidth: '100%', width: '100%' }} disableElevation onClick={remove}>
                        <DeleteForeverOutlinedIcon />
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </ArrayField.Items>
            </Grid>

            <Box mx={1} my={2}>
              <Typography variant="button">
                Total:  {currency.format(total)}
              </Typography>
            </Box>
            <Box>
              <Button
                onClick={add}
                disabled={fields.length === betsLimit}
                variant="outlined" sx={{
                  marginRight: '8px',
                  width: '120px',
                  color: '#333',
                  borderColor: '#333',
                  ":hover": {
                    borderColor: '#333',
                  }
                }}>Add</Button>
              <Button onClick={next} variant="contained" disableElevation
                sx={{
                  width: '120px',
                  color: '#FFF',
                  bgcolor: '#333',
                  ":hover": {
                    bgcolor: '#333',
                  }
                }}
              >Next</Button>
            </Box>
          </>
        )}
      </ArrayField>

    </Multistep.Step>
  );
}
