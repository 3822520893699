import React from 'react';
import { useFieldApi } from 'informed';
import { Typography } from '@mui/material';

export default function FieldErrorMessage({ field, format, formatMethod }) {
  const fieldApi = useFieldApi(field);

  return (
    <Typography color="error">{
      fieldApi.exists() && (
        format ? formatMethod(fieldApi.getError()) && fieldApi.getError() : fieldApi.getError()
      )
    }</Typography>
  );
}
