import fetch from "@lottoasean/fetch-utils";

export const fetchNumbers = ({ token, params }) => {
  return fetch({
    url: 'numbers',
    method: "GET",
    params,
    token
  });
};
