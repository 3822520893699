import fetch from "@lottoasean/fetch-utils";

export const fetchLottos = ({ token, params }) => {
  return fetch({
    url: 'lottos',
    method: "GET",
    params,
    token
  });
};

export const fetchLotto = ({ token, id, params }) => {
  return fetch({
    url: `lottos/${id}`,
    method: "GET",
    params,
    token
  });
};
